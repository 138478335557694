import * as Sentry from "@sentry/browser"
import { BrowserTracing } from "@sentry/tracing"

Sentry.init({
  dsn: "https://10b3a9b2d907fe9f7eff022613df7c00@o4506142303715328.ingest.sentry.io/4506584619941888",
  sampleRate: 1.0, // Adjust this value in production
  integrations: [new BrowserTracing()],
  beforeSend(event) {
    //
    return event
  },
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "console") {
      return null
    }
    return breadcrumb
  },
  // ...
})
